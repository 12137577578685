import ProfileCard from "../components/ProfileCard";
import Navbar from "../components/Navbar";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
import { ReactLenis, useLenis} from '@studio-freight/react-lenis';
import backgroundImage from '../assets/images/neon_bg6.webp';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { motion } from 'framer-motion';

const Layout = ({ children, showProfileCardOnMobile }) => {

  const lenis = useLenis(({ scroll }) => {

  })

  return (
    <ReactLenis root>
      <div className="bg-slate-950 min-h-screen">
        {/* Desktop layout */}
        <div className="hidden xl:flex min-h-screen">
        <aside 
            className="w-1/3 flex-shrink-0 p-4 flex justify-center items-center border-r-2 border-sky-600 h-screen sticky top-0 bg-cover bg-center"
            style={{ backgroundImage: `url(${backgroundImage})` }}
          >
            <ProfileCard />
          </aside>
          <div className="flex-grow flex flex-col">
            <Navbar />
            <main className="flex-grow p-4 overflow-y-auto mx-4">
              {children}
            </main>
            <Footer />
          </div>
        </div>

        {/* Mobile layout */}
        <div className="flex flex-col min-h-screen xl:hidden">
          <main className="flex-grow p-4">
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            {showProfileCardOnMobile && <ProfileCard />}
          </motion.div>
            {children}
          </main>
          <Footer isMobile={true}/>
          <MobileNavbar />
        </div>
      </div>
      <ToastContainer />
    </ReactLenis>
    
    
  );
};

export default Layout;
